import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateRecipeCollectionCommand } from "../../apiClient/clients";
import { ProblemDetails } from "../../utils";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent } from "../../config/firebase";
import queryKeys from "../queryKeys";
import reactQueryOnApiLimitError from "../../utils/reactQueryOnApiLimitError";
import { toast } from "react-hot-toast";
import useAuthentication from "../useAuthentication";

//NOTE: This is a workaround for azure functions not supporting [FromForm] as well as NSwag issues.
//Essentially, when the request succeeds fire off a request to  upload the image.

type Props = {
  onSuccess?: () => void;
  onError?: (err: ProblemDetails) => void;
};

const useCreateRecipeCollection = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: createRecipeCollectionMutate } = useMutation(
    async (command: CreateRecipeCollectionCommand) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("create_recipe_collection");
      return apiClient.createRecipeCollection(command);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.recipeCollections],
        });

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.users],
        });

        toast.success("Created recipe collection! 📝");
        onSuccess && onSuccess();
      },
      onError: (err: any) => reactQueryOnApiLimitError(err, onError),
    }
  );

  return { isLoading, createRecipeCollectionMutate };
};

export default useCreateRecipeCollection;
