import { appId, auth0Domain } from "@/auth.config";
import { App as CapApp } from "@capacitor/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isNative } from "../utils";
import appInsights from "../utils/appInsights";

const useCapacitorAuth = (isIos = false) => {
  // Get the callback handler from the Auth0 React hook
  // const { handleRedirectCallback } = useAuthentication();
  const router = useRouter();
  const [slug, setSlug] = useState("");
  // const { isAuthenticated, isLoading: isAuthenticationLoading } =
  //   useAuthentication();

  //TODO: Fix localhost here
  const callbackUri = isNative
    ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
    : "http://localhost:3000";

  useEffect(() => {
    
    if (isNative) {
      appInsights.trackEvent({
        name: "useCapAuth - is native",
        properties: { message:"useCapAuth - is native"},
      });
      
      CapApp.addListener("appUrlOpen", async ({ url }) => {
        appInsights.trackEvent({
          name: "AppUrlOpen - first  line",
          properties: { url, message: "AppUrlOpen - first line" },
        });

        // if (url.startsWith(callbackUri)) {
        //   if (
        //     url.includes("state") &&
        //     (url.includes("code") || url.includes("error"))
        //   ) {
        //     await handleRedirectCallback(url);
        //   }

        //   await Browser.close();
        // }
        const slug = url.split(".app").pop();
        if (slug) {
          setSlug(slug);
          // localStorage.setItem("redirectSlug", slug);
          await router.push(slug);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isNative) {
      //If, the authentication ISNT loading (e.g. the loading screen is dplayed)
      //and they've authenticated.
      if (slug) {
        (async () => {
          appInsights.trackEvent({
            name: "AppUrlOpen - waiting 2 seconds",
            properties: { slug, message: "Waiting 2 seconds" },
          });

          await new Promise((r) => setTimeout(r, 2000));
          appInsights.trackEvent({
            name: "AppUrlOpen - waited 2 seconds",
            properties: { slug, message: "Waited 2 seconds" },
          });

          await router.push(slug);
          appInsights.trackEvent({
            name: "AppUrlOpen - pushed to slug",
            properties: { slug, message: "Pushed to slug" },
          });

          setSlug("");
        })();
      }
    }
  }, [slug]);
};

export default useCapacitorAuth;
